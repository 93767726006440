import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { root, line } from './styling/showMore.module.scss';

const UP_ARROW = 'ʌ';
const DOWN_ARROW = 'v';

const SHOW_MORE = `${DOWN_ARROW} Show more ${DOWN_ARROW}`;
const SHOW_LESS = `${UP_ARROW} Show less ${UP_ARROW}`;

class ShowMore extends Component {
  static propTypes = {
    onClick: PropTypes.func,
    open: PropTypes.bool,
  };

  render() {
    return (
      <div className={root} onClick={this.props.onClick}>
          <span>{this.props.open ? SHOW_LESS : SHOW_MORE}</span>
          <div className={line}></div>
      </div>
    );
  }
}

export default ShowMore;
