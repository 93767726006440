import firebase from "firebase";

const firebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyBl45BAvrz00BF3psWrndZwH5fefm0cb3c",
  authDomain: "wherestheclaw2.firebaseapp.com",
  databaseURL: "https://wherestheclaw2.firebaseio.com",
  projectId: "wherestheclaw2",
  storageBucket: "wherestheclaw2.appspot.com",
  messagingSenderId: "1076238896566",
  appId: "1:1076238896566:web:78bba57059cf39bcd74517",
  measurementId: "G-J3DML77SKX"
});

const analytics = firebase.analytics();
const db = firebaseApp.firestore();

export { db, analytics };
