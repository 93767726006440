import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DateFnsUtils from '@date-io/date-fns';
import { Button, Modal } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment-timezone';
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from '@material-ui/pickers';

import { db } from './firebase';
import firebase from 'firebase';
import { btn, modal } from './styling/spotted.module.scss';

const styles = theme => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
});

class SpottedButton extends Component {
  static propTypes = {
    business: PropTypes.object,
    onChange: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      openModal: false,
      selectedDateTime: moment(),
      submitting: false,
    }
  }

  handleSpotted = () => {
    this.setState(prevState => ({
      ...prevState,
      openModal: true,
      selectedDateTime: moment(),
    }))
  };

  handleClose = () => {
    this.setState(prevState => ({
      ...prevState,
      openModal: false,
    }))
  };

  handleDateChange = (date) => {
    this.setState(prevState => ({
      ...prevState,
      selectedDateTime: moment(date)
    }));
  };

  handleSubmitSpotted = () => {
    this.setState(prevState => ({
      ...prevState,
      submitting: true,
    }));

    // TODO if no id, bail with error message
    const { ID: id } = this.props.business;
    if (!id) {
      return;
    }

    const collection = db.collection('business_meta_data');
    collection.where('id', '==', id)
      .get()
      .then(querySnapshot => {
        const last_seen = firebase.firestore.Timestamp.fromMillis(this.state.selectedDateTime.valueOf());

        if (querySnapshot.empty) {
          return collection.add({ id, last_seen, });
        }

        return querySnapshot.docs[0].ref.update({ last_seen });
      })
      .then(() => this.props.onChange())
      .then(() => this.handleClose())
      .then(() => this.setState(prevState => ({ ...prevState, submitting: false })))
      .catch(err => console.log('\n\n err', err, '\n\n'));
  };

  render() {
    const { classes } = this.props;

    return (
      <div>
        <Button className={btn} variant="contained" onClick={this.handleSpotted}>
          SPOTTED THE CLAW
        </Button>
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.state.openModal}
          onClose={this.handleClose}
        >
          <div className={`${classes.paper} ${modal}`}>
            <div>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDateTimePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy hh:mm"
                  margin="normal"
                  id="date-picker-inline"
                  label="Spotted at"
                  value={this.state.selectedDateTime}
                  onChange={this.handleDateChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  disableFuture
                  autoOk
                />
              </MuiPickersUtilsProvider>
            </div>
            <Button id="spottedCancelButton" variant="contained" onClick={this.handleClose}>
              CANCEL
            </Button>
            <Button id="spottedSubmitButton" variant="contained" onClick={this.handleSubmitSpotted}>
              MARK THE SPOT
            </Button>
          </div>
        </Modal>
      </div>
    );
  }
}

export default withStyles(styles)(SpottedButton);
