import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import GoogleMapReact from 'google-map-react';

import BusinessPopout from './businessPopout';
import Spotted from './spotted';
import { marker, me } from './styling/googleMap.module.scss';
import style from './googleMapStyle';

const DEFAULT_CENTER = { lat: 43.6426, lng: -79.3871 };
const DEFAULT_ZOOM = 15;


const MapMarker = ({ text, onClick: handleClick }) => (
  <div className={marker}
       style={{ color: '#6b6b6b' }}
       onClick={handleClick}>
  </div>
);

const MeMarker = () => (<div className={me} />);

class GoogleMap extends Component {
  static propTypes = {
    mapSettings: PropTypes.object,
    locations: PropTypes.array,
    metaData: PropTypes.object,
    timeZoneId: PropTypes.string,
    refreshFirebaseData: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      selected: null,
      lastSelected: null,
      center: null,
    }
  };

  // TODO: this seems messy and uncessary
  static getDerivedStateFromProps(nextProps, prevState) {
    const stateChanges = {};
    let locChanged = false;

    if (!_.isEqual(prevState.userLoc, nextProps.userLoc)) {
      locChanged = true;
      stateChanges.userLoc = nextProps.userLoc;
    }

    if (!_.isEqual(prevState.mapSettings, nextProps.mapSettings)) {
      locChanged = true;
      stateChanges.mapSettings = nextProps.mapSettings;
    }

    if (locChanged) {
      stateChanges.center = nextProps.mapSettings.center || nextProps.userLoc;
      stateChanges.lastSelected = prevState.selected;
      stateChanges.selected = null;
    } else if (!_.isEqual(prevState.selected, prevState.lastSelected)) {
      const selected = prevState.selected || prevState.lastSelected;
      stateChanges.center = {
        lat: selected.Latitude,
        lng: selected.Longitude,
      }
    }

    return stateChanges;
  }

  closePopout = () => {
    if (!this.state.selected) {
      return;
    }

    this.setState({ ...this.state, selected: null, lastSelected: this.state.selected });
  };

  lastSeenChanged = () => {
    this.props.refreshFirebaseData();
  };

  createMap = () => {

    return {
      clickableIcons: false,
      styles: style,
    };
  };

  render() {
    const markers = this.props.locations.length !== 0 ? this.props.locations.map((business) => (
      <MapMarker
        lat={business.Latitude}
        lng={business.Longitude}
        text={business.Name}
        onClick={() => this.setState({
          ...this.state, selected: business, lastSelected: this.state.selected
        })}
        key={business.ID}
      />
    )) : [];

    if (!!this.props.userLoc) {
      markers.push(<MeMarker
        {...this.props.userLoc}
        key={'me'}
      />);
    }

    return (
      <div style={{ width: '100%', height: '100%' }}>
        {this.state.selected
        && <BusinessPopout
          onClose={this.closePopout}
          business={this.state.selected}
          metaData={this.props.metaData[this.state.selected.ID]}
          timeZoneId={this.props.timeZoneId}
        />}
        {this.state.selected
        && <Spotted
          business={this.state.selected}
          onChange={this.lastSeenChanged}
        />}
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyDwiIRFXasnekP2iK7Ve1NAiLDr2FDOio0' }}
          defaultCenter={DEFAULT_CENTER}
          defaultZoom={DEFAULT_ZOOM}
          center={this.state.center}
          zoom={this.props.mapSettings.zoom + 3}
          onDrag={this.closePopout}
          options={this.createMap}
        >
          {markers}
        </GoogleMapReact>
      </div>
    );
  }
}

export default GoogleMap;
