import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

import ShowMore from './showMore';
import { root } from './styling/businessPopout.module.scss';

class BusinessPopout extends Component {
  static propTypes = {
    onClose: PropTypes.func,
    business: PropTypes.object,
    metaData: PropTypes.object,
    timeZoneId: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      showAdditional: false,
    }
  };

  _toggleShowAdditional = () => {
    this.setState(state => ({
        ...state,
        showAdditional: !state.showAdditional,
      })
    );
  };

  _renderAdditionalInfo = () => {
    return (
      <div>
        <div>
          {this.props.business.Address}
        </div>
        <div>
          {this.props.business.Distance}mi
        </div>
        <div>
          <a href={`tel:${this.props.business.Phone}`}>
            {this.props.business.Phone}
          </a>
        </div>
      </div>
    );
  };

  _renderAdditionalData = () => {
    if (!this.props.metaData) {
      return null;
    }
    const metaData = moment.unix(this.props.metaData.last_seen.seconds);
    metaData.tz(this.props.timeZoneId);
    return (
      <div>
        <div>Last seen at:</div>
        <div>{metaData.format('MMM Do YYYY hh:mm')}</div>
      </div>
    );
  };

  render() {
    return (
      <div className={root}>
        <div>
          <div>
            {this.props.business.Name}
          </div>
          {this._renderAdditionalData()}
          <ShowMore open={this.state.showAdditional} onClick={this._toggleShowAdditional} />
          {this.state.showAdditional && this._renderAdditionalInfo()}
        </div>
      </div>
    );
  }
}

export default BusinessPopout;
