import React, { Component } from 'react';
import { Button, CircularProgress, Modal, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import { Promise } from 'bluebird';
import { map, overlay, spinner, filter } from './styling/googleMap.module.scss';


import GoogleMap from './googleMap';
import { db, analytics } from './firebase';

import './styling/App.scss';

const styles = theme => ({
  root: {
    height: 300,
    flexGrow: 1,
    transform: 'translateZ(0)',
    // The position fixed scoping doesn't work in IE 11.
    // Disable this demo to preserve the others.
    '@media all and (-ms-high-contrast: none)': {
      display: 'none',
    },
  },
  modal: {
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
});

class App extends Component {
  constructor(props) {
    super(props);

    this.rootRef = React.createRef();

    this.state = {
      locations: [],
      loading: false,
      zip: '',
      videoURL: 'https://www.whiteclaw.com/videos/white-claw-website-wave-cinemagraph.mp4',
      mapSettings: {
        zoom: 13,
        center: {
          lat: 43.642567,
          lng: -79.387054,
        },
      },
      userLoc: null,
      timeZoneId: null,
      /* No need to actully check age apparently
      confirmedAge: false,
      underAge: false,
      */
      confirmedAge: true,
      underAge: false,
    }
  }

  componentDidMount(props) {
    this.getUserLoc();
  }

  getUserLoc = () => {
    navigator.geolocation.getCurrentPosition(
      position => {
        const { latitude, longitude } = position.coords;
        const userLoc = {
          lat: latitude, lng: longitude,
        };

        this.setState({ userLoc });
        this.fetchLocationsFromMAG({ loc: userLoc });
      },
      (err) => {
        if (err.code) {
          analytics.logEvent('loc_perms_denied');
          alert('Please enable location permissions in order to use location services.')
        } else {
          alert('Something went wrong while getting user location.')
        }
      }
    );
  };

  handleChange = (e) => {
    const val = e.target.value;

    // limit zip to 5 chars
    if (val.length > 5) {
      return
    }

    this.setState({ ...this.state, zip: val })
  };

  fetchTMZ = (loc) => {
    fetch(`https://maps.googleapis.com/maps/api/timezone/json?location=${loc.lat},${loc.lng}&timestamp=${new Date().getUTCSeconds()}&key=AIzaSyDwiIRFXasnekP2iK7Ve1NAiLDr2FDOio0`)
      .then(res => res.json())
      .then(({ timeZoneId }) => this.setState((prevState) => ({ ...prevState, timeZoneId })));
  };

  fetchLocationsFromMAG = ({ zip, loc }) => {
    analytics.logEvent('start_mag_query');
    this.setState({
      ...this.state,
      loading: true,
    });

    const queryString = !!zip ?
      `Address=${zip}&CategoryID=1` :
      `Address=&CategoryID=1&Latitude=${loc.lat}&Longitude=${loc.lng}`;

    if (!!zip) {
      analytics.logEvent('search_by_zip', { zip });
    } else {
      analytics.logEvent('search_by_loc', { loc });
    }

    fetch(`https://api.markanthony.com/StoreLocatorUSA/Accounts?${queryString}`)
      .then(response => response.json())
      .then(data => {
        const { Businesses, MapSettings: { Zoom, MapCenter: { Latitude, Longitude } } } = data;
        this.setState({
          ...this.state,
          loading: false,
          locations: Businesses,
          mapSettings: { zoom: Zoom, center: { lat: Latitude, lng: Longitude } }
        });
        this.fetchFirebaseData(Businesses);
        this.fetchTMZ({ lat: Latitude, lng: Longitude });
        analytics.logEvent('end_mag_query');
      })
  };

  fetchFirebaseData = (businesses) => {
    let firebaseMetaData = {};
    Promise.map(businesses, b =>
      db.collection('business_meta_data').where('id', '==', b.ID)
        .get()
        .then(querySnapshot => {
          // TODO this is ugly
          querySnapshot.forEach(doc => firebaseMetaData[b.ID] = doc.data());
        })
    )
      .then(() => {
        this.setState(prevState => ({ ...prevState, firebaseMetaData }))
      });
  };

  refreshFirebaseData = () => {
    this.fetchFirebaseData(this.state.locations);
  };

  handleSubmit = () => {
    this.fetchLocationsFromMAG({ zip: this.state.zip });
  };

  handleNearMe = () => {
    if (!!this.state.userLoc) {
      this.fetchLocationsFromMAG({ loc: this.state.userLoc });
    } else {
      this.getUserLoc();
    }
  };

  handleAgeConfirm = () => {
    this.setState(prevState => ({
      ...prevState,
      confirmedAge: true,
      underAge: false,
    }));
  };

  handleUnderAge = () => {
    this.setState(prevState => ({
      ...prevState,
      confirmedAge: true,
      underAge: true,
    }));
  };

  render() {
    const { classes } = this.props;

    return (
      <div className="App">
        <div id="background-video">
          <video loop autoPlay>
            <source src={this.state.videoURL} type="video/mp4" />
            <source src={this.state.videoURL} type="video/ogg" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div id="header">
          <a href="https://www.whiteclaw.com/#me">
            <img src={process.env.PUBLIC_URL + 'white_claw_logo.png'} alt="logo" id="logo" />
          </a>
        </div>
        <div id="body">
          <div id="form">
            <TextField
              id="filled-uncontrolled"
              label="ZIP"
              margin="normal"
              variant="filled"
              inputProps={{ type: 'number' }}
              value={this.state.zip}
              onChange={this.handleChange}
            />
            <Button id="submit" variant="contained" onClick={this.handleSubmit}>
              FIND THE CLAW
            </Button>
            <Button id="nearMe" variant="contained" onClick={this.handleNearMe}>
              <MyLocationIcon />
            </Button>
          </div>
          <div className={map}>
            {this.state.loading &&
            (<div className={overlay}>
                <div className={filter} />
                <div className={spinner}>
                  <CircularProgress color="inherit" />
                </div>
              </div>
            )}
            <GoogleMap
              userLoc={this.state.userLoc}
              mapSettings={this.state.mapSettings}
              locations={this.state.locations}
              metaData={this.state.firebaseMetaData}
              timeZoneId={this.state.timeZoneId}
              refreshFirebaseData={this.refreshFirebaseData}
            />
          </div>
        </div>
        <Modal
          disablePortal
          disableEnforceFocus
          disableAutoFocus
          open={!this.state.confirmedAge || this.state.underAge}
          aria-labelledby="server-modal-title"
          className={classes.modal}
          container={() => this.rootRef.current}
        >
          <div className={classes.paper}>
            <h2 id="server-modal-title">
              {this.state.underAge ? 'Please return when you are of age' : 'Are you over 21?'}
            </h2>
            {!this.state.underAge &&
            (<div>
              <Button id="confirmAge" variant="contained" onClick={this.handleAgeConfirm}>
                YES
              </Button>
              < Button id="underAge" variant="contained" onClick={this.handleUnderAge}>
                NO
              </Button>
            </div>)
            }
          </div>
        </Modal>
      </div>
    );
  }
}

export default withStyles(styles)(App);
